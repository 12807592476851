import { useState, useEffect, useRef } from 'react';
import LinhaTabelaCliente from './linhaTabelaCliente';
import styles from './MapaOpPorCliente.module.css';
import { filterData, SearchType } from 'filter-data';
import CampoForm from '../CampoForm';
import { app } from '../../config/firebase';
import { getFirestore, doc, onSnapshot, setDoc } from 'firebase/firestore';

const TabelaAcao = ({baseOperacoesAcao, baseComentarios, acao, baseOpcoesAtivas, baseAcoesCallCoberta}) => {

    // VARIÁVEL FIRESTORE
    const db = getFirestore(app);

    // VARIÁVEL DE ESTADO
    const [comentario, setComentario] = useState(undefined)

    // TODA VEZ QUE A VARIÁVEL DE ESTADO DE BASE MUDAR
    useEffect(() =>{

        // ATUALIZA O COMENTÁRIO
        if(baseComentarios!=undefined){
            if(baseComentarios.Comentarios!=undefined){
                if(baseComentarios.Comentarios[acao.acao] != undefined && baseComentarios.Comentarios[acao.acao] != ""){
                    if(baseComentarios.Comentarios[acao.acao] != comentario){
                        setComentario(baseComentarios.Comentarios[acao.acao])
                    }
                }
            }
        }

    },[baseComentarios])

    // CAPTURA VARIAÇÕES NA VARIÁVEL COMENTÁRIO
    useEffect(() => {

        // CÓPIA DA BASE COMPLETA
        if(baseComentarios!=undefined){
            if(baseComentarios.Comentarios!=undefined){
                var baseComentariosTemp = JSON.parse(JSON.stringify((baseComentarios.Comentarios)))
                
                if(comentario != undefined){
                    if(baseComentariosTemp[acao.acao] != comentario){
                        baseComentariosTemp[acao.acao] = comentario

                        // ATUALIZA O COMENTÁRIO NO FIREBASE
                        setDoc(doc(db, "opcoesMap", "Comentarios"), {
                            Comentarios: baseComentariosTemp
                        }).then((ticker)=>{
                        });
                    }
                }
            }
        }
    },[comentario])

    // VARIÁVEL 
    var arrayTickersTemp = []
    var arrayTickers = []
    var arrayClientesTemp = []
    var arrayClientes = []

    // ADICIONA ALGUMAS INFOS IMPORTANTES PARA ATUALIZAÇÃO DA PÁGINA
    if(baseOpcoesAtivas.length===0){}else{
        // ARRAY COM OS TICKERS DA AÇÃO
        for(const ticker in baseOperacoesAcao){

            // VERIFICA O NOTIONAL TOTAL ALOCADO NESSE TICKER
            var notionalTicker = 0
            for(const cliente in baseOperacoesAcao[ticker]){
                notionalTicker = notionalTicker + baseOperacoesAcao[ticker][cliente]["notional"]
                var inputCliente = {
                    nome: cliente,
                    chave: baseOperacoesAcao[ticker][cliente]["chave"]
                }
                arrayClientesTemp.push(inputCliente)
            }

            // SE FOR 0 NÃO FAZ NADA SE NÃO SEGUE NORMAL
            if(notionalTicker>0){
                // CAPTURA O STRIKE DO TICKER
                // FITLRA NA BASE AS OPERAÇÕES COM TICKER DESEJADO
                var searchConditions = [
                    {
                    key: 'Ativo',
                    value: ticker,
                    type: SearchType.EQ,
                    },
                ];

                var baseFiltradaAtivo = filterData(baseOpcoesAtivas, searchConditions);

                // VERIFICA SE ESSE TICKER ESTÁ NA BASE FILTRADA OU NÃO
                if(baseFiltradaAtivo.length===0){}else{
                    // PEGA O VALOR DO STRIKE DA PRIMEIRA VARIÁVEL NA BASE
                    var strike = baseFiltradaAtivo[0]["strike"]
                    var ultimoPrecoOpcao = baseFiltradaAtivo[0]["ultimo"]

                    // ADICIONAR UMA COLORAÇÃO NA COLUNA DE ACORDO COM A POSIÇÃO E O TIPO
                    var tickerWarning = ""
                    var tipo = baseFiltradaAtivo[0]["tipo"]
                    var tipoN = 0

                    if(tipo==="PUT"){
                        tipoN = 0
                        if(strike < acao.precoAcao *0.95){
                            tickerWarning = "itemOk"
                        }else if(strike < acao.precoAcao ){
                            tickerWarning = "itemWarning"
                        }else{
                            tickerWarning = "itemBad"
                        }
                    }else{
                        tipoN = 1
                        if(strike > acao.precoAcao *1.05){
                            tickerWarning = "itemOk"
                        }else if(strike > acao.precoAcao ){
                            tickerWarning = "itemWarning"
                        }else{
                            tickerWarning = "itemBad"
                        } 
                    }

                    var tickerInput = {
                        strike: strike,
                        ticker: ticker,
                        warning: tickerWarning,
                        tipoN: tipoN,
                        tipo: tipo,
                        precoUltimo:ultimoPrecoOpcao
                    }

                    arrayTickersTemp.push(tickerInput)
                }

            }
        }

        arrayTickersTemp.sort((a, b) => b.strike - a.strike);
        arrayTickersTemp.sort((a, b) => a.tipoN - b.tipoN);
        arrayTickers = arrayTickersTemp
    }

    // MONTA UM ARRAY DE CLIENTES EXCLUSIVOS
    arrayClientes = [...new Set(arrayClientesTemp.map((valor) => valor.nome))]

    return(
        <div className={``}>  
            <div className={`${styles.commentsBox}`}>
                <div className={`${styles.commentInput}`}>
                    <form id="form-comentario" data-form="ComentarioOpOpcoes">
                        <div className="row">
                            <CampoForm 
                                valor = {comentario}
                                altura = "textoLargo"
                                aoAlterado = {setComentario}
                                nomeCampo= "Comentário" 
                                campoId="comentario" 
                                tipo="text" 
                                dataCampo="comentarioOpOpcoes" 
                                placeholder="" 
                                dataMask=""
                                required= {false}  
                                largura= "12"
                            />
                        </div>
                    </form>
                </div>
            </div>
            <div className={`${styles.overflow}`}>
                <table className={`${styles.table} table`}>
                    <thead  className={`${styles.tableHead}`}>
                        <tr>
                            <th scope="col" className={`${styles.tableTitle} ${styles.tableNomeTitle} `}>
                                <p>Ticker</p>
                                <p>Tipo / Strk</p>
                            </th>
                            <th scope="col" className={`${styles.tableTitle} ${styles.tableCCobertaTitle} `}>
                                <p>C Cobertas</p>
                                <p>#Ações | Calls | PM | PA</p>
                            </th>
                            {arrayTickers.map((tickerInput)=>{
                                return(
                                    <th key={tickerInput["ticker"]} scope="col" className={`${styles.tableTitle}`}>
                                        <div className={`${styles.tableTitleContent}`}>
                                            <div><b>{tickerInput["ticker"]}</b></div>
                                            <div>{tickerInput["tipo"]} / {tickerInput["strike"]}</div>
                                            <div className={`${styles.tableTitleDetail}`}>Qtd | Not | Rtrn | C Coberta</div>
                                        </div>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {arrayClientes.map((cliente) =>{

                            var chaveCliente = ""
                            // LOOP PARA ACHAR A CHAVE CORRESPONDENTE DO CLIENTE
                            for(let i=0; i < arrayClientesTemp.length; i++){
                                if(cliente === arrayClientesTemp[i]["nome"]){
                                    chaveCliente = arrayClientesTemp[i]["chave"]
                                }
                            }

                            return(
                                <LinhaTabelaCliente baseAcoesCallCoberta={baseAcoesCallCoberta} key={"linhaTabela" + cliente} acao={acao} arrayTickers={arrayTickers} chave={chaveCliente} cliente={cliente} baseOperacoesAcao={baseOperacoesAcao}/>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TabelaAcao